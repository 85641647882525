import React from 'react';
import { Animation } from 'gatsby-theme-portfolio-minimal/src/components/Animation';
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PageSection } from 'gatsby-theme-portfolio-minimal/src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHouse,
  faClock,
  faHeart
} from '@fortawesome/free-solid-svg-icons'

import * as classes from './style.module.css';

export function DetailSection(props: PageSection): React.ReactElement {

    return (
        <Animation type="fadeUp">
            <Section anchor={props.sectionId} heading={props.heading}>
                <div className={classes.Details}>
                  <Animation key={1} className={classes.Detail} type="scaleIn" delay={1 * 150}> 
                        <FontAwesomeIcon icon={faClock} size="5x" />
                        <h4>Ingen ventetid</h4>
                        <ul>
                            <li>Ingen ventetid, venteværelser eller transport</li>
                            <li>Start dit forløb inden for 24 timer</li>
                            <li>Du behøver ikke henvisning fra din læge</li>
                        </ul>
                    </Animation>
                  <Animation key={2} className={classes.Detail} type="scaleIn" delay={2 * 150}> 
                        <FontAwesomeIcon icon={faHouse} size="5x" />
                        <h4>Trygge rammer</h4>
                        <ul>
                            <li>Tal med din psykolog over video eller skrift hvor og når du vil</li>
                            <li>Helt privat og diskret</li>
                            <li>Skriv med din psykolog, hvis du er bedre til at formulere dig på skrift</li>
                        </ul>
                    </Animation>
                  <Animation key={3} className={classes.Detail} type="scaleIn" delay={3 * 150}> 
                        <FontAwesomeIcon icon={faHeart} size="5x"/>
                        <h4>Tilfredsheds garanti</h4>
                        <ul>
                            <li>Vi refunderer det fulde beløb, hvis du ikke er tilfreds eller ønsker at skifte til en anden psykolog – uanset hvad årsagen måtte være.</li>
                        </ul>
                    </Animation>
                        
                </div>
            </Section>
        </Animation>
    );
}
