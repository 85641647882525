import React from 'react';
import { Animation } from 'gatsby-theme-portfolio-minimal/src/components/Animation';
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PageSection } from 'gatsby-theme-portfolio-minimal/src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMoneyBill,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

import * as classes from './style.module.css';

export function PriceSection(props: PageSection): React.ReactElement {

    return (
        <Animation type="fadeUp">
            <Section anchor={props.sectionId} heading={props.heading}>
                <div className={classes.Prices}>
                  <Animation key={1} className={classes.Price} type="scaleIn" delay={1 * 150}> 
                        <h1>Priser</h1>
                        <FontAwesomeIcon icon={faMoneyBill} size="5x" />
                        <ul>
                            <li><FontAwesomeIcon icon={faCheck}  /> Adgang til alt skriftlig materiale</li>
                            <li><FontAwesomeIcon icon={faCheck}  /> Skriv så meget du vil til din egen psykolog</li>
                            <li><FontAwesomeIcon icon={faCheck}  /> 2 timers konsultation om måneden, som det passer dig </li>
                        </ul>
                    </Animation>
                        
                </div>
            </Section>
        </Animation>
    );
}
