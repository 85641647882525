import React from "react";
import {
  AboutSection,
  ArticlesSection,
  ContactSection,
  HeroSection,
  InterestsSection,
  Page,
  ProjectsSection,
  Seo,
} from "gatsby-theme-portfolio-minimal";
import { DetailSection } from "../../content/sections/details";
import { PriceSection } from "../../content/sections/prices";

export default function IndexPage() {
  return (
    <>
      <Seo title="Webpsykologi" />
      <Page >
        <HeroSection sectionId="hero" />
        <DetailSection sectionId="details"  />
        <ArticlesSection sectionId="articles" heading="Seneste blog indlæg" sources={['Blog']} />
        <AboutSection sectionId="about" heading="Om os" />
        <ProjectsSection sectionId="features" heading="Psykologerne" />
        <PriceSection sectionId="prices" />
      </Page>
    </>
  );
}
